<template>
  <el-card class="tcu-list height100percent">
    <div>
      <SearchBar
        :searchBtns="searchBtns"
        :searchFormItems="searchFormItems"
        @search="searchHandler"
      />
    </div>
    <div class="tcu-list_table">
      <CommonTable
        ref="tcuTable"
        :tableItems="tableItems"
        :actions="actions"
        :getTableDataFunc="getPatientList"
        table-type="patients"
        :outParams="params"
      />
    </div>
  </el-card>
</template>

<script>
import SearchBar from '@/components/SearchBar'
import CommonTable from '@/components/CommonTable/index.vue'

import { permitActionMixin } from '@/mixin/permitActionMixin'
import {
  getPatientList
} from '@/services'
import jsonData from './jsonData'
export default {
  components: {
    SearchBar,
    CommonTable
  },
  mixins: [permitActionMixin],
  data () {
    return {
      searchBtns: jsonData.searchBtns,
      searchFormItems: jsonData.searchFormItems,
      tableItems: jsonData.tableItems,
      getPatientList,
      params: {}
    }
  },
  created () {
    this.initActionData(jsonData, 'patients')
  },
  methods: {
    searchHandler (form) {
      if (form.planned_at_gteq && form.planned_at_gteq.length > 0) {
        const arr = form.planned_at_gteq
        delete form.planned_at_gteq
        form.planned_at_gteq = arr[0]
        form.planned_at_lteq = arr[1]
      }
      this.$set(this, 'params', form)
      this.$nextTick(() => {
        if (this.$refs.tcuTable) {
          this.$refs.tcuTable.reloadData()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tcu-list {
  &_table {
    height: calc(100% - 120px);
  }
}

::v-deep {
  .el-card__body {
    height: 100%;
    box-sizing: border-box;
  }
}
</style>