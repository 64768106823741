import utils from '@/utils/Utils'
import { mapState } from 'vuex'
export const permitActionMixin = {
  data () {
    return {
      actions: [],
      pageActions: []
    }
  },
  computed: {
    ...mapState({
      permitActions: (state) => state.user.permitActions
    })
  },
  methods: {
    async initActionData (jsonData, kind) {
      if(!this.permitActions || this.permitActions.length == 0) await this.$store.dispatch('user/setPermitActions')
      if (jsonData.tableActions) {
        this.actions = jsonData.tableActions.filter(
          (d) => utils.hasPermission(this.permitActions, kind, d.id))
      }
      if (jsonData.pageActions) {
        this.pageActions = jsonData.pageActions.filter(
          (d) => utils.hasPermission(this.permitActions, kind, d.id))
      }
    }
  }
}