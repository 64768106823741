export default {
  searchFormItems: [
    {
      formtype: 'input',
      prop: 'ic_cont',
      label: '',
      placeholder: 'Patient IC',
      object: {
        style: 'width: 168px; margin: 0 12px 15px 0;'
      }
    },
    {
      formtype: 'daterange',
      prop: 'actual_admission_date_gteq',
      label: '',
      placeholder: 'Admission Date',
      object: {
        style: 'width: 295px; margin: 0 12px 15px 0;',
        startPlaceholder: 'Admission Date',
        endPlaceholder: 'Admission Date'
      }
    },
    {
      formtype: 'daterange',
      prop: 'actual_discharge_date_gteq',
      label: '',
      placeholder: 'Discharge Date',
      object: {
        style: 'width: 295px; margin: 0 12px 15px 0;',
        startPlaceholder: 'Discharge Date',
        endPlaceholder: 'Discharge Date'
      }
    },
    {
      formtype: 'select',
      prop: 'billed_eq',
      label: '',
      placeholder: 'Billed',
      options: [
        {
          name: 'Yes',
          value: true
        },
        {
          name: 'No',
          value: false
        }
      ],
      object: {
        style: 'width: 103px; margin: 0 18px 15px 0;'
      }
    },
    {
      formtype: 'select',
      prop: 'paid_eq',
      label: '',
      placeholder: 'Paid',
      options: [
        {
          name: 'Yes',
          value: true
        },
        {
          name: 'No',
          value: false
        }
      ],
      object: {
        style: 'width: 103px;margin: 0 12px 15px 0;'
      }
    }
  ],
  searchBtns: [
    {
      type: 'primary',
      label: 'Filter',
      style: 'width: 98px;',
      methodsType: 'submit'
    },
    {
      type: '',
      label: 'Clear',
      style: 'width: 98px;margin-left: 12px;',
      methodsType: 'clear'
    }
  ],
  tableItems: [
    {
      prop: 'number',
      label: 'Record Number',
      classname: 'text-blue alink',
      width: '188px',
      clickInfo: {
        url: '/workplace/patientInfo/details',
        key: 'id'
      }
    },
    {
      prop: 'patient',
      label: 'Patient',
      classname: '',
      list: [
        {
          label: '',
          prop: 'name'
        },
        {
          label: '',
          prop: 'ic'
        }
      ]
    },
    {
      prop: 'actual_admission_date',
      label: 'Admission Date',
      classname: ''
    },
    {
      prop: 'actual_discharge_date',
      label: 'Discharge Date',
      width: '135px',
      classname: ''
    },
    {
      prop: 'to_institution_los',
      label: 'LOS in Days',
      classname: ''
    },
    {
      prop: 'has_accompany',
      label: 'Total Amount',
      classname: ''
    },
    {
      prop: 'billed',
      label: 'Billed',
      classname: ''
    },
    {
      prop: 'paid',
      label: 'Paid',
      classname: ''
    },
    {
      prop: 'diagnosis',
      label: 'Claim Diagnosis',
      classname: ''
    }
  ],
  tableActions: [
    {
      label: 'Edit',
      url: '/workplace/patientInfo/update?formType=billing',
      type: 'jump',
      id: 'update'
    }
  ]
}